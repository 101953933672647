<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-margin-file-sign pl-8 pr-8">
      <v-row class="mt-11">
        <v-col class="text-left" cols="6">
          <h1 class="report-users__title">Registro de firmas por usuario</h1>
          <!-- <p class="txt-title-report">REGISTRO DE FIRMAS POR USUARIO</p> -->
        </v-col>
        <v-col cols="3">
          <p>
            <v-btn
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
              class="mt-3"
            >
              Descargar lista
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
        <v-col cols="3">
          <v-btn
            :to="{ name: 'reportPercentage' }"
            block
            dark
            rounded
            color="#466be3"
            class="report-users__botton mt-3"
          >
            Ver estatus por porcentaje
          </v-btn>
        </v-col>
      </v-row>
      <!-- Filtros -->
      <v-row>
        <v-col cols="2">
          <v-autocomplete
            v-model="form.clientId"
            :items="listClient"
            label="Seleccione el cliente"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.campaignId"
            :items="listCampaign"
            label="Seleccione la campaña"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.profileId"
            :items="listProfile"
            label="Seleccione el cargo"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="form.contractorId"
            :items="listContractor"
            label="Seleccione contratante"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="form.statusId"
            :items="listStatus"
            label="Seleccione el estado"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1 table-report-header report-sign-margin__table"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            <!-- Sin resultados de busqueda -->
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            <!-- Sin Data -->
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <!-- Loading -->
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <!-- Nombre -->
            <template v-slot:item.name="{ item }">
              <div class="float-left mt-2 pr-4">
                <img
                  style="border-radius: 120px"
                  width="35"
                  height="35"
                  :src="item.img"
                />
              </div>
              <div>
                <p class="mb-0 mt-2">{{ item.name }}</p>
                <p class="mb-0 mb-2">{{ item.documentNumber }}</p>
              </div>
            </template>
            <!-- estado -->
            <template v-slot:item.activeInactive="{ item }">
              <p class="mb-0 mb-2">Activo</p>
            </template>
            <!-- Firma -->
            <template v-slot:item.status="{ item }">
              <p class="mb-0 mb-2" v-if="item.signed">Firmado</p>
              <p class="mb-0 mb-2" v-else>Sin firma</p>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-margin-file-sign">
      <v-row justify="center" class="ml-0 mr-0 px-3 my-5">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            El registro de firmas por usuarios
          </p>
        </v-col>
        <v-col cols="12" class="py-3 px-2">
          <v-btn
            :to="{ name: 'reportPercentage' }"
            block
            dark
            rounded
            color="#466be3"
          >
            <strong> Ver estatus por porcentaje </strong>
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <p>
            <v-btn
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
            >
              <strong> Descargar lista </strong>
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.clientId"
            :items="listClient"
            label="Seleccione el cliente"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.campaignId"
            :items="listCampaign"
            label="Seleccione la campaña"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.profileId"
            :items="listProfile"
            label="Seleccione el cargo"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.contractorId"
            :items="listContractor"
            label="Seleccione contratante"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0 px-2">
          <v-autocomplete
            v-model="form.statusId"
            :items="listStatus"
            label="Seleccione el estado"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>

        <!--
          SE COMENTA ESTO PORQUE ESTA TABLA NO VA A ESTAR DISPONIBLE EN VERSION MOBILE
        <v-col cols="12" class="py-0 px-2">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            style="background-color: #f9f9f900"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-0 table-report-header"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            ** Sin resultados de busqueda
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            ** Sin Data
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            ** Loading
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            ** Nombre
            <template v-slot:item.name="{ item }">
              <div class="float-left mt-2 pr-4">
                <img
                  style="border-radius: 120px"
                  width="35"
                  height="35"
                  :src="item.img"
                />
              </div>
              <div>
                <p class="mb-0 mt-2">{{ item.name }}</p>
                <p class="mb-0 mb-2">{{ item.documentNumber }}</p>
              </div>
            </template>
            ** estado
            <template v-slot:item.activeInactive="{ item }">
              <p class="mb-0 mb-2">Activo</p>
            </template>
            ** Firma
            <template v-slot:item.status="{ item }">
              <p class="mb-0 mb-2" v-if="item.signed">Firmado</p>
              <p class="mb-0 mb-2" v-else>Sin firma</p>
            </template>
          </v-data-table>
        </v-col> -->
      </v-row>
    </div>

    <!-- <v-dialog v-model="message.dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 request-project">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  name: "upload",
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: false,
      listClient: [],
      listProfile: [],
      listCampaign: [],
      listContractor: [],
      listStatus: [
        {
          name: "ACTIVO",
          id: 1,
        },
        {
          name: "INACTIVO",
          id: 2,
        },
      ],
      loading: {
        table: false,
      },
      form: {
        profileId: null,
        clientId: null,
        campaignId: null,
        contractorId: null,
        statusId: null,
      },
      items: [],
      headers: [
        // {
        //   text: "",
        //   align: "start",
        //   sortable: false,
        //   value: "img",
        // },
        {
          text: "Empleado",
          align: "start",
          value: "user.full_name",
          class: "report-sign-margin__header",
        },
        {
          text: "Cargo",
          value: "user.profile.name",
          class: "report-sign-margin__header",
        },
        {
          text: "Cliente",
          value: "user.client.name",
          class: "report-sign-margin__header",
        },
        {
          text: "Campaña",
          value: "user.campaign.name",
          class: "report-sign-margin__header",
        },
        {
          text: "Fecha de retiro",
          value: "user.retirement_date",
          class: "report-sign-margin__header",
        },
        {
          text: "Activo/Inactivo",
          value: "activeInactive",
          class: "report-sign-margin__header",
        },
        {
          text: "Estado",
          value: "status",
          class: "report-sign-margin__header",
        },
      ],
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  watch: {
    "form.profileId": function (n) {
      this.getReportSign();
      this.dinamicFilter();
    },
    "form.clientId": function (n) {
      this.getReportSign();
      this.dinamicFilter();
    },
    "form.campaignId": function (n) {
      this.getReportSign();
      this.dinamicFilter();
    },
    "form.contractorId": function (n) {
      this.getReportSign();
      this.dinamicFilter();
    },
    "form.statusId": function (n) {
      this.getReportSign();
      this.dinamicFilter();
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getClients() {
      Api.MarginCommisions()
        .getClients(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listClient = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCampaigns() {
      Api.MarginCommisions()
        .getCampaigns(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listCampaign = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getContractors() {
      Api.MarginCommisions()
        .getContractor(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listContractor = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProfile() {
      Api.MarginCommisions()
        .getProfile(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listProfile = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportSign() {
      this.loading.table = true;
      var data = {
        clientId:
          this.form.clientId != null ? parseInt(this.form.clientId) : null,
        campaignId:
          this.form.campaignId != null ? parseInt(this.form.campaignId) : null,
        profileId:
          this.form.profileId != null ? parseInt(this.form.profileId) : null,
        contractor: this.form.contractorId,
      };
      this.items = [];
      Api.MarginCommisions()
        .getReportSign(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.items = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    download() {
      var data = {
        clientId:
          this.form.clientId != null ? parseInt(this.form.clientId) : null,
        campaignId:
          this.form.campaignId != null ? parseInt(this.form.campaignId) : null,
        profileId:
          this.form.profileId != null ? parseInt(this.form.profileId) : null,
        contractor: this.form.contractorId,
      };
      this.loadingAll = true;
      Api.MarginCommisions()
        .getReportSignDownload(data, this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            console.log("***");
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }

          // setTimeout(() => {
          //   this.message.dialog = false;
          // }, 3000);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    dinamicFilter() {
      this.loadingAll = true;
      let data = {
        clientId: this.form.clientId,
        campaignId: this.form.campaignId,
        profileId: this.form.profileId,
        contractorId: this.form.contractorId,
      };
      Api.MarginCommisions()
        .dinamicFilter(data, this.token)
        .then((res) => {
          if (res.data.cod === 0) {
            this.listClient = [];
            this.listCampaign = [];
            this.listProfile = [];
            this.listContractor = [];
            res.data.data.forEach((element) => {
              let client = {
                id: element.client_id,
                name: element.client_name.toUpperCase(),
              };
              this.listClient.push(client);
              let campaign = {
                id: element.campaign_id,
                name: element.campaign_name.toUpperCase(),
              };
              this.listCampaign.push(campaign);
              let profile = {
                id: element.profile_id,
                name: element.profile_name.toUpperCase(),
              };
              this.listProfile.push(profile);
              this.listContractor.push(element.contractor.toUpperCase());
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    // this.getClients();
    // this.getCampaigns();
    // this.getProfile();
    // this.getContractors();
    this.dinamicFilter();
    this.getReportSign();
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";
.mb-xl-margin-file-sign {
  display: block;
}

.mb-sm-margin-file-sign {
  display: none;
}

.txt-title-report {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #353535;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.report-sign-margin__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

.report-sign-margin__header {
  color: #353535 !important;
}

tr,
th,
td,
span .report-sign-margin__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.report-users__title {
  font-family: "RobotoRegular";
  font-weight: 700;
  font-size: 40px;
  line-height: 59px;
  color: #353535;
}

.report-users__botton:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file-sign {
    display: none;
  }
  .mb-sm-margin-file-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file-sign {
    display: none;
  }
  .mb-sm-margin-file-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file-sign {
    display: none;
  }
  .mb-sm-margin-file-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file-sign {
    display: none;
  }
  .mb-sm-margin-file-sign {
    display: block;
    text-align: initial;
  }
}
</style>
